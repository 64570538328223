import React from 'react'
import { Link } from 'react-router-dom'
import image1 from '../images/club_1.png'
import image2 from '../images/club_2.png'
import image3 from '../images/club_3.png'
import image4 from '../images/club_4.png'

export default function Products() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="club_img_sec">
                                <div className="d-flex align-items-start mob_club_img_sec">
                                    <div className="nav flex-column nav-pills me-3 mob-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                            <div className="club_title_img" style={{ backgroundImage: `url(${image1}` }}></div>
                                        </button>
                                        <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                            <div className="club_title_img" style={{ backgroundImage: `url(${image2}` }}></div>
                                        </button>
                                        <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                            <div className="club_title_img" style={{ backgroundImage: `url(${image3}` }}></div>
                                        </button>
                                        <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                            <div className="club_title_img" style={{ backgroundImage: `url(${image4}` }}></div>
                                        </button>
                                    </div>
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                                            <figure>
                                                <img src={image1} alt="" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                                            <figure>
                                                <img src={image2} alt="" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">
                                            <figure>
                                                <img src={image3} alt="" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabindex="0">
                                            <figure>
                                                <img src={image4} alt="" className="img-fluid" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="clud_details_div">
                                <div className="clud_title_details_div">
                                    <h3 className="club_details__title ">Millbrook Park Primary School (NW7 1JF)</h3>
                                    <p className="text-shadow-none">Establishment Millbrook Park Primary School ; Address: School House Lane, London, NW7 1JF ; Local authority: Barnet (302) ; Headteacher / Principal: Headteacher</p>
                                    <h4 className="price mb-4"><span className="price_span">Price :- <span>£80</span></span></h4>
                                    <div className="d-flex product_info_details">
                                        <div className="club_div_gap">
                                            <h3 className="mb-3">Key Stage 1</h3>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-clock"></i> <span>3:10 pm To 4:10 pm</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-calendar-days"></i><span>22-09-2023 To 15-12-2023</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-calendar-week"></i><span>Every Tuesday of the week</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-person-chalkboard"></i><span>Spaces Remaining: 10</span></p>
                                        </div>
                                        <div className="club_div_gap">
                                            <h3 className="mb-3">Key Stage 2</h3>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-clock"></i> <span>4:10 pm To 6:10 pm</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-calendar-days"></i><span>23-09-2023 To 16-12-2023</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-calendar-week"></i><span>Every Tuesday of the week</span></p>
                                            <p className="d-flex club_p_gap"><i className="fa-solid fa-person-chalkboard"></i><span>Spaces Remaining: 10</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="child_select_div mt-3 ">
                                    <h3>Select Child</h3>
                                    <div class="child_select_div_inner d-flex align-items-center">
                                        <select name="" id="cusSelectbox">
                                            <option value="Select">Select Child</option>
                                            <option value="India">First Child</option>
                                            <option value="Nepal">Second Child</option>
                                        </select>
                                        <button class="add_child_btn" data-bs-toggle="modal" data-bs-target="#add_child"
                                            data-bs-whatever="@mdo">Add Child</button>
                                    </div>
                                </div>
                                <div className="choose_key_stage">
                                    <label className="col-form-label">Select Key Stage*</label>
                                    <div className="d-flex gap-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="key_stage" id="ks1" value="KS1" required />
                                            <label className="form-check-label" for="ks1">KS1</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="key_stage" id="ks2" value="KS2" required />
                                            <label className="form-check-label" for="ks2">KS2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 text-center justify-content-center gap-3">
                        <button className="book_btn">Add To Cart</button>
                        <Link to="/checkout" className="book_btn">Book Now</Link>
                    </div>
                </div>
            </main>

            <div className="modal fade add_child" id="add_child" tabindex="-1" aria-labelledby="add_childLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Child</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="/product.html" id="childForm">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form_div">
                                            <label htmlFor="first-name" className="col-form-label">First Name of child*</label>
                                            <input type="text" className="form-control" id="first-name" name="first_name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form_div">
                                            <label htmlFor="surname" className="col-form-label">Surname of child*</label>
                                            <input type="text" className="form-control" id="surname" name="surname" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form_div">
                                            <label htmlFor="date-of-birth" className="col-form-label">Date of Birth*</label>
                                            <input type="date" className="form-control" id="date-of-birth" name="date_of_birth"
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form_div">
                                            <label htmlFor="year-group" className="col-form-label">Year Group*</label>
                                            <select name="year_group" id="year-group" required>
                                                <option value="">Select Year</option>
                                                <option value="1">1 Year</option>
                                                <option value="2">2 Year</option>
                                                <option value="3">3 Year</option>
                                                <option value="4">4 Year</option>
                                                <option value="5">5 Year</option>
                                                <option value="6">6 Year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="form_div">
                                            <label htmlFor="additional-details" className="col-form-label">Additional details about your
                                                child</label>
                                            <input type="text" className="form-control" id="additional-details"
                                                name="additional_details" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="form_div">
                                            <label htmlFor="medical-conditions" className="col-form-label">Medical conditions or
                                                allergies</label>
                                            <input type="text" className="form-control" id="medical-conditions"
                                                name="medical_conditions" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center p-0">
                                    <button type="submit" className="btn btn-primary">Add My Child</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
