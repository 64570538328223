import React from 'react'
import { Link } from 'react-router-dom'

export default function Register() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    {/* <div className="robo_img_div text-center">
                        <figure>
                            <img src="./images/robo-img.svg" alt="" className="robo_img">
                        </figure>
                        <h1 className="text-white">RoboThink</h1>
                    </div> */}
                    <div className="form_div text-center mt-5">
                        <form className="login_form" id="registrationForm" onsubmit="return validateForm()">
                            <h2 className="mb-4 text-clr">Register</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentForename" className="form-label custom_lable">Parent/Guardian Name*</label>
                                <input type="text" className="form-control" id="parentForename" required />
                                <span id="forenameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentSurname" className="form-label custom_lable">Your current location</label>
                                <input type="text" id="locationInput" className="form-control" required />
                                <span id="locationError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="parentSurname" className="form-label custom_lable">ZIP code</label>
                                <input type="text" id="zip_code" className="form-control" required />
                                <span id="zipError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="email" className="form-label custom_lable">Email Address*</label>
                                <input type="email" className="form-control" id="email" required />
                                <span id="emailError" className="error"></span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="contactNumber" className="form-label custom_lable">Contact Number*</label>
                                <input type="text" className="form-control" id="contactNumber" required />
                                <span id="contactError" className="error"></span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="inputPassword" className="form-label custom_lable">Password</label>
                                <input type="password" className="form-control" id="inputPassword" required />
                                <span id="passwordError" className="error"></span>
                                <span className="pass_hide"><i className="fa-regular fa-eye-slash" id="togglePassword"></i></span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="confirmPassword" className="form-label custom_lable">Confirm Password</label>
                                <input type="password" className="form-control" id="confirmPassword" required />
                                <span id="confirmPasswordError" className="error"></span>
                                <span className="pass_hide"><i className="fa-regular fa-eye-slash" id="togglePassword2"></i></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn" type="submit">Next</button>
                            </div>
                            <div className="register_div text-center">
                                <Link to="/login" className="btn_forgot">Login</Link>
                            </div>
                        </form>

                        <form className="another_form login_form" id="anotherForm" style={{ display: 'none' }} action="index.html" onsubmit="return validateChildForm()">
                            <h2 className="mb-4 text-clr">Add Child</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childName" className="form-label custom_lable">Name of child*</label>
                                <input type="text" className="form-control" id="childName" required />
                                <span id="childNameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDOB" className="form-label custom_lable">Date of Birth*</label>
                                <input type="date" className="form-control" id="childDOB" required />
                                <span id="childDOBError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="yearGroup" className="form-label custom_lable">Year Group*</label>
                                <select name="year_group" id="yearGroup" required>
                                    <option value="">Select Year</option>
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                </select>
                                <span id="yearGroupError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDetails" className="form-label custom_lable">Additional details about your child</label>
                                <input type="text" className="form-control" id="childDetails" required />
                                <span id="childDetailsError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childConditions" className="form-label custom_lable">Medical conditions or allergies</label>
                                <input type="text" className="form-control" id="childConditions" required />
                                <span id="childConditionsError" className="error"></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn add_my_child" type="submit">Add My Child</button>
                            </div>
                            <div className="register_div text-center">
                                <button className="btn_forgot add_another_child" type="button" onclick="showAddAnotherChildForm()">Add Another Child</button>
                            </div>
                        </form>
                        <form className="add_other_child login_form" id="addAnothreChild" action="index.html" onsubmit="return validateChildForm()" style={{ display: 'none' }}>
                            <h2 className="mb-4 text-clr">Add Another Child</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childName" className="form-label custom_lable">Name of child*</label>
                                <input type="text" className="form-control" id="childName" required />
                                <span id="childNameError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDOB" className="form-label custom_lable">Date of Birth*</label>
                                <input type="date" className="form-control" id="childDOB" required />
                                <span id="childDOBError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="yearGroup" className="form-label custom_lable">Year Group*</label>
                                <select name="year_group" id="yearGroup" required>
                                    <option value="">Select Year</option>
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                </select>
                                <span id="yearGroupError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childDetails" className="form-label custom_lable">Additional details about your child</label>
                                <input type="text" className="form-control" id="childDetails" required />
                                <span id="childDetailsError" className="error"></span>
                            </div>
                            <div className="mb-4 position-relative">
                                <label htmlFor="childConditions" className="form-label custom_lable">Medical conditions or allergies</label>
                                <input type="text" className="form-control" id="childConditions" required />
                                <span id="childConditionsError" className="error"></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn add_my_child" type="submit">Add My Child</button>
                            </div>
                            <div className="register_div text-center">
                                <button className="btn_forgot add_another_child" type="button" onclick="showAddAnotherChildForm()">Add Another Child</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}
