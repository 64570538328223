import React from 'react'

export default function MyclassNamees() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="my-classes">
                        <div className="row">
                            <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="current-tab" data-bs-toggle="pill" data-bs-target="#current" type="button" role="tab" aria-controls="current" aria-selected="true">Current</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="upcoming-tab" data-bs-toggle="pill" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">Upcoming</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="previous-tab" data-bs-toggle="pill" data-bs-target="#previous" type="button" role="tab" aria-controls="previous" aria-selected="false">Previous</button>
                                </li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="current" role="tabpanel" aria-labelledby="current-tab" tabindex="0">
                                    <div className="table-responsive custom_border_rd">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Plan</th>
                                                    <th scope="col">Class</th>
                                                    <th scope="col">Student</th>
                                                    <th scope="col">Next Lesson</th>
                                                    <th style={{ width: '115px' }} scope="col">Course End</th>
                                                    <th scope="col">Ability</th>
                                                    <th scope="col">Instructor</th>
                                                    <th style={{ width: '115px' }} scope="col">Booking Type</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><button>Switch</button></th>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Rajveer Maharaul</td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                    <td><p className="view_btn_tbl" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span><i className="fa-solid fa-calendar-days"></i> View</span></p></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><button>Switch</button></th>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Rajveer Maharaul</td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                    <td><p className="view_btn_tbl" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span><i className="fa-solid fa-calendar-days"></i> View</span></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab" tabindex="0">
                                    <div className="table-responsive custom_border_rd">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Plan</th>
                                                    <th scope="col">Class</th>
                                                    <th scope="col">Student</th>
                                                    <th style={{ width: '115px' }} scope="col">Next Lesson</th>
                                                    <th scope="col">Course End</th>
                                                    <th scope="col">Ability</th>
                                                    <th scope="col">Instructor</th>
                                                    <th style={{ width: '115px' }} scope="col">Booking Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><button>Switch</button></th>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                    <td><p className="view_btn_tbl" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span><i className="fa-solid fa-calendar-days"></i> View</span></p></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><button>Switch</button></th>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                    <td><p className="view_btn_tbl" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span><i className="fa-solid fa-calendar-days"></i> View</span></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="previous" role="tabpanel" aria-labelledby="previous-tab" tabindex="0">
                                    <div className="table-responsive custom_border_rd">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Class</th>
                                                    <th scope="col">Student</th>
                                                    <th scope="col">Next Lesson</th>
                                                    <th style={{ width: '115px' }} scope="col">Course End</th>
                                                    <th scope="col">Ability</th>
                                                    <th scope="col">Instructor</th>
                                                    <th style={{ width: '115px' }} scope="col">Booking Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Rajveer Maharaul</td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Mondays at 4:00 PM</strong> Whitehall Park Primary School
                                                    </td>
                                                    <td>Rajveer Maharaul</td>
                                                    <td>Monday, 6 November 2023 - 4:00 PM</td>
                                                    <td>Dec 22, 2023</td>
                                                    <td>Video Game Designing</td>
                                                    <td>Mr Rajveer Maharaul</td>
                                                    <td>Standard</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
