import React from 'react'

export default function MyPayments() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="transation_info">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <div className="payment_card sticky-top">
                                    <div className="pay_btn_div  text-end">
                                        <button><i className="fa-regular fa-credit-card"></i> Pay</button>
                                    </div>
                                    <div className="balance_div">
                                        <h1 className="m-0"><span className="amount_span">£50.00</span> <span className="amount_card">Credit</span></h1>
                                        <p>Account Balance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="transation_history">
                                    <h2>Transactions</h2>
                                    <div className="transation_card d-flex justify-content-between col_gap">
                                        <div className="transation_left d-flex col_gap">
                                            <div className="transation_icon">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                            <div className="transation_dis w-75">
                                                <p className="mb-0 fs-5">Jun 26, 2023</p>
                                                <p className="mb-0"><strong>Charge</strong></p>
                                                <p className="mb-0"><small>CL538-794 - 17/04/2023 to 31/07/2023 (5 lessons) - Robotics Club - Mondays 11:00 AM for Rajveer Maharaul.</small></p>
                                            </div>
                                        </div>
                                        <div className="transation_right">
                                            <p className="mb-0">£50.00</p>
                                            <p className="text-danger">-£0.00</p>
                                        </div>
                                    </div>
                                    <div className="transation_card d-flex justify-content-between col_gap mt-4">
                                        <div className="transation_left d-flex col_gap">
                                            <div className="transation_icon">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                            <div className="transation_dis w-75">
                                                <p className="mb-0 fs-5">Apr 21, 2023</p>
                                                <p className="mb-0"><strong>Credit</strong></p>
                                                <p className="mb-0"><small>CL538-794 - 17/04/2023 to 31/07/2023 (5 lessons) - Robotics Club - Mondays 11:00 AM for Rajveer Maharaul.</small></p>
                                            </div>
                                        </div>
                                        <div className="transation_right text-end">
                                            <p className="mb-0">£50.00</p>
                                            <p className="text-nowrap text-clr-gr">+£150.00</p>
                                        </div>
                                    </div>
                                    <div className="transation_card d-flex justify-content-between col_gap">
                                        <div className="transation_left d-flex col_gap">
                                            <div className="transation_icon">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                            <div className="transation_dis w-75">
                                                <p className="mb-0 fs-5">Jun 26, 2023</p>
                                                <p className="mb-0"><strong>Charge</strong></p>
                                                <p className="mb-0"><small>CL538-794 - 17/04/2023 to 31/07/2023 (5 lessons) - Robotics Club - Mondays 11:00 AM for Rajveer Maharaul.</small></p>
                                            </div>
                                        </div>
                                        <div className="transation_right">
                                            <p className="mb-0">£50.00</p>
                                            <p className="text-danger">-£0.00</p>
                                        </div>
                                    </div>
                                    <div className="transation_card d-flex justify-content-between col_gap mt-4">
                                        <div className="transation_left d-flex col_gap">
                                            <div className="transation_icon">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                            <div className="transation_dis w-75">
                                                <p className="mb-0 fs-5">Apr 21, 2023</p>
                                                <p className="mb-0"><strong>Credit</strong></p>
                                                <p className="mb-0"><small>CL538-794 - 17/04/2023 to 31/07/2023 (5 lessons) - Robotics Club - Mondays 11:00 AM for Rajveer Maharaul.</small></p>
                                            </div>
                                        </div>
                                        <div className="transation_right text-end">
                                            <p className="mb-0">£50.00</p>
                                            <p className="text-nowrap text-clr-gr">+£150.00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
