import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="footer_top_div">
                        <img src="https://www.robothink.co.uk/wp-content/uploads/2020/03/robot-group.svg" alt=""
                            className="footer-img" />
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer_div">
                                <h2></h2>
                                <figure>
                                    <img src="https://www.robothink.co.uk/wp-content/uploads//2020/01/image.svg" alt="" />
                                </figure>
                                <p>
                                    RoboThink offers fun and exciting Robotics, Engineering and
                                    Coding programs where engineers, tinkerers and builders of all
                                    ages can explore the exciting world of STEM!
                                </p>
                                <div className="btn_box mb-3 mt-5">
                                    <span className="custom-btn py-3 px-4"><Link to="/">Find RoboThink</Link></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer_div">
                                <h2>About</h2>
                                <p><Link to="/">Home</Link></p>
                                <p><Link to="/">Our Story</Link></p>
                                <p><Link to="/">FAQ</Link></p>
                                <p><Link to="/">Press</Link></p>
                                <p><Link to="/">Join the Team</Link></p>
                                <p><Link to="/">Terms & Conditions</Link></p>
                                <p><Link to="/">Privacy Policy</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer_div">
                                <h2>Programs</h2>
                                <p><Link to="/">Online Programs</Link></p>
                                <p><Link to="/">Coding</Link></p>
                                <p><Link to="/">Robotics</Link></p>
                                <p><Link to="/">Engineering</Link></p>
                                <p><Link to="/">Video Game Design</Link></p>
                                <p><Link to="/">Birthday Parties</Link></p>
                                <p><Link to="/">Learning Center Membership</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer_div">
                                <h2>Contact</h2>
                                <p></p>
                                <p className="d-flex add_div">
                                    <i className="fa-solid fa-location-dot"></i>
                                    <span>20-22 Wenlock Road, London N1 7GU</span>
                                </p>
                                <p className="d-flex add_div">
                                    <i className="fa-solid fa-phone"></i>
                                    <a href="tel:+44 78621-50200">+44 78621-50200</a>
                                </p>
                                <p className="d-flex add_div">
                                    <i className="fa-solid fa-envelope"></i>
                                    <a href="mailto:info@robothink.co.uk">info@robothink.co.uk</a>
                                </p>
                                <div className="social_link">
                                    <Link to="/"><i className="fa-brands fa-youtube"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-facebook-f"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-twitter"></i></Link>
                                    <Link to="/"><i className="fa-brands fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
