import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import image1 from '../images/school_img-1.svg';
import image2 from '../images/children-celebrating-birthday.jpg';
import image3 from '../images/holiday_camp_club.svg';
import image4 from '../images/learning_centre.jpg';
import image5 from '../images/workshops_club.png';


export default function HomePage() {

    const [selecteddiv, setselecteddiv] = useState('our_services_div');

    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="our_services_div" id="our_services_div" style={selecteddiv == "our_services_div" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_1" id="club_div_1"
                                    onClick={() => {
                                        setselecteddiv('school_for_club');
                                    }}>
                                    <h2 className="club_title">Afterschool Club</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_2"
                                    onClick={() => {
                                        setselecteddiv('birthday_party_club');
                                    }}>
                                    <h2 className="club_title">Birthday Party</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_3"
                                    onClick={() => {
                                        setselecteddiv('holiday_camp_club');
                                    }}>
                                    <h2 className="club_title">Holiday Camp</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_4"
                                    onClick={() => {
                                        setselecteddiv('learning_centre_club');
                                    }}>
                                    <h2 className="club_title">Learning Centre Membership</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_5"
                                    onClick={() => {
                                        setselecteddiv('workshops_club');
                                    }}>
                                    <h2 className="club_title">Workshops</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="school_for_club" style={selecteddiv == "school_for_club" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="back_to_home_page">
                                <p>
                                    <span onClick={() => {
                                        setselecteddiv('our_services_div');
                                    }}>Home -</span>
                                    <span> Afterschool Club</span>
                                </p>
                            </div>
                            <div className="school_search_div">
                                <div className="searchbox">
                                    <button className="btn-menu">
                                        <i className="fa-solid fa-school"></i>
                                    </button>
                                    <input id="search" type="text" placeholder="Search..." name="search" className="search" />
                                    <button className="btn-search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image1} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image1} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image1} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="birthday_party_club" style={selecteddiv == "birthday_party_club" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="back_to_home_page">
                                <p>
                                    <span onClick={() => {
                                        setselecteddiv('our_services_div');
                                    }}>Home -</span>
                                    <span> Birthday Party</span>
                                </p>
                            </div>
                            <div className="school_search_div">
                                <div className="searchbox">
                                    <button className="btn-menu">
                                        <i className="fa-solid fa-school"></i>
                                    </button>
                                    <input id="search" type="text" placeholder="Search..." name="search" className="search" />
                                    <button className="btn-search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image2} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image2} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image2} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                        </div>
                    </div>
                    <div className="school_for_club" id="holiday_camp_club" style={selecteddiv == "holiday_camp_club" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="back_to_home_page">
                                <p>
                                    <span onClick={() => {
                                        setselecteddiv('our_services_div');
                                    }}>Home -</span>
                                    <span> Holiday Camp</span>
                                </p>
                            </div>
                            <div className="school_search_div">
                                <div className="searchbox">
                                    <button className="btn-menu">
                                        <i className="fa-solid fa-school"></i>
                                    </button>
                                    <input id="search" type="text" placeholder="Search..." name="search" className="search" />
                                    <button className="btn-search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image3} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i> <span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"> <i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image3} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i> <span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"> <i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image3} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i> <span>Millbrook Park Primary School</span> </p>
                                            <p className="d-flex school_details_gap"> <i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="learning_centre_club" style={selecteddiv == "learning_centre_club" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="back_to_home_page">
                                <p>
                                    <span onClick={() => {
                                        setselecteddiv('our_services_div');
                                    }}>Home -</span>
                                    <span> Learning Centre Membership</span>
                                </p>
                            </div>
                            <div className="school_search_div">
                                <div className="searchbox">
                                    <button className="btn-menu">
                                        <i className="fa-solid fa-school"></i>
                                    </button>
                                    <input id="search" type="text" placeholder="Search..." name="search" className="search" />
                                    <button className="btn-search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image4} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image4} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image4} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="school_for_club" id="workshops_club" style={selecteddiv == "workshops_club" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="back_to_home_page">
                                <p>
                                    <span onClick={() => {
                                        setselecteddiv('our_services_div');
                                    }}>Home -</span>
                                    <span> Workshops</span>
                                </p>
                            </div>
                            <div className="school_search_div">
                                <div className="searchbox">
                                    <button className="btn-menu">
                                        <i className="fa-solid fa-school"></i>
                                    </button>
                                    <input id="search" type="text" placeholder="Search..." name="search" className="search" />
                                    <button className="btn-search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image5} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image5} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <Link to="/club-selection" className="club_selection_tab">
                                    <div className="school_div">
                                        <img src={image5} alt="" />
                                        <div className="school_details">
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-school"></i><span>Millbrook Park Primary School</span></p>
                                            <p className="d-flex school_details_gap"><i className="fa-solid fa-map-location-dot"></i><span>School House Ln, Mill Hill, London NW7 1JF, UK</span> </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
