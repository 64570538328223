import React from 'react'
import image1 from '../images/club_1.png'

export default function Checkout() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="cart_div">
                                <h2 className="text-center">Your Cart</h2>
                                <div className="cart_item_div">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th className="text-white w-20">S.N.</th>
                                                            <th scope="col" className="text-white">Product</th>
                                                            <th scope="col" className="text-white">Child Name</th>
                                                            <th scope="col" className="text-white">Key Stage</th>
                                                            <th scope="col" className="text-white">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <h6 className="pt-3">1.</h6>
                                                            </td>
                                                            <td>
                                                                <div className="cart_img_div d-flex align-items-center">
                                                                    <span className="d-flex align-items-center mob_view">
                                                                        <img src={image1} width="80" alt="" />
                                                                    </span>
                                                                    <p classNameName="cart_item_name d-flex flex-column mb-0"><strong>Math Club</strong><small> Millbrook Park Primary School</small></p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">First Child</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Ks1</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">$75.00</h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h6 className="pt-3">2.</h6>
                                                            </td>
                                                            <td>
                                                                <div className="cart_img_div d-flex align-items-center">
                                                                    <span className="d-flex align-items-center mob_view">
                                                                        <img src={image1} width="80" alt="" />
                                                                    </span>
                                                                    <p className="cart_item_name d-flex flex-column mb-0"><strong>Math Club</strong><small> Millbrook Park Primary School</small></p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Second Child</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Ks2</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">$75.00</h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h6 className="pt-3">3.</h6>
                                                            </td>
                                                            <td>
                                                                <div className="cart_img_div d-flex align-items-center">
                                                                    <span className="d-flex align-items-center mob_view">
                                                                        <img src={image1} width="80" alt="" />
                                                                    </span>
                                                                    <p className="cart_item_name d-flex flex-column mb-0"><strong>Math Club</strong><small> Millbrook Park Primary School</small></p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Third Child</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Ks1</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">$75.00</h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h6 className="pt-3">4.</h6>
                                                            </td>
                                                            <td>
                                                                <div className="cart_img_div d-flex align-items-center">
                                                                    <span className="d-flex align-items-center mob_view">
                                                                        <img src={image1} width="80" alt="" />
                                                                    </span>
                                                                    <p className="cart_item_name d-flex flex-column mb-0"><strong>Math Club</strong><small> Millbrook Park Primary School</small></p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">fourth Child</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">Ks2</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="pt-3">$75.00</h6>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="checkout_div mt-5">
                                <div className="promo-text">
                                    <h3 className="promo-text_h2 mb-3">Apply Coupon Code</h3>
                                    <div className="coupon_text d-flex" >
                                        <input type="text" className="form-control" autocomplete="off" id="coupon_code" name="coupon_code" placeholder="Coupon code" value="" />

                                        <div className="input-group-append">
                                            <button className="book_btn w-100 px-3 d-flex gap-2 align-items-center" type="button" id="apply_coupon"><i className="fa fa-check-circle"></i>Apply</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <h3>Invoice</h3>
                                    <div className="item_sm_details d-flex justify-content-between">
                                        <p className="item_sm_details_name d-flex flex-column"><span className="cst_clr">Math Club</span> <small>(Millbrook Park Primary School)</small></p>
                                        <p className="totla_price_div">$300.00</p>
                                    </div>
                                    <div className="item_sm_details d-flex justify-content-between">
                                        <p className="item_sm_details_name d-flex flex-column"><span className="cst_clr">Tax</span> </p>
                                        <p className="totla_price_div">$10.00</p>
                                    </div>
                                    <hr />
                                    <div className="item_sm_details d-flex justify-content-between">
                                        <p className="item_sm_details_name d-flex flex-column"><span className="cst_clr">Total</span> </p>
                                        <p className="totla_price_div">$310.00</p>
                                    </div>
                                    <hr />
                                    <div className="row text-center justify-content-center">
                                        <button className="book_btn">Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
