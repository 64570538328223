import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function ManageAccount() {

    const [selecteddiv, setselecteddiv] = useState('our_services_div');

    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="our_services_div" id="our_services_div" style={selecteddiv == "our_services_div" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_1" id="club_div_1"
                                    onClick={() => {
                                        setselecteddiv('edit-account');
                                    }}>
                                    <h2 className="club_title">Edit Account Details</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_2"
                                    onClick={() => {
                                        setselecteddiv('manage-card');
                                    }}>
                                    <h2 className="club_title">Manage Your Cards</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_3"
                                    onClick={() => {
                                        setselecteddiv('password-change');
                                    }}>
                                    <h2 className="club_title">Change Password</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="edit-account" style={selecteddiv == "edit-account" ? { display: 'block' } : { display: 'none' }}>
                        <div className="back_to_home_page">
                            <p>
                                <span onClick={() => {
                                    setselecteddiv('our_services_div');
                                }}>Home -</span>
                                <span> Edit Account Details</span>
                            </p>
                        </div>
                        <div className="personal_details_div">
                            <div className="row">
                                <div className="col-lg-10 col-md-12 m-auto">
                                    <form action="" className="personal_info_detalis">
                                        <h2 className="mb-4 text-clr">Personal Details</h2>
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-10 col-md-10">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="title" className="form-label custom_lable">Title</label>
                                                    <select name="title" id="title" className="select_pro">
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="Miss">Miss</option>
                                                        <option value="Mrs">Mrs</option>
                                                        <option value="Dr">Dr</option>
                                                        <option value="Professor">Professor</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="first_name" className="form-label custom_lable">FIRST NAME <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="first_name" name="first_name" />
                                                    <span id="first_nameError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="last_name" className="form-label custom_lable">LAST NAME <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="last_name" name="last_name" />
                                                    <span id="last_nameError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="email" className="form-label custom_lable">Email <span>*</span></label>
                                                    <input type="email" className="form-control" id="email" name="email" />
                                                    <span id="emailError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="home_phone" className="form-label custom_lable">HOME PHONE NUMBER</label>
                                                    <input type="text" className="form-control" id="home_phone" name="home_phone" />
                                                    <span id="home_phoneError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="mobile_phone" className="form-label custom_lable">MOBILE PHONE NUMBER</label>
                                                    <input type="text" className="form-control" id="mobile_phone" name="mobile_phone" />
                                                    <span id="mobile_phoneError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="work_phone" className="form-label custom_lable">WORK PHONE NUMBER</label>
                                                    <input type="text" className="form-control" id="work_phone" name="work_phone" />
                                                    <span id="work_phoneError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="mb-4 text-clr">Address Details</h2>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="address" className="form-label custom_lable">ADDRESS <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="address" name="address" />
                                                    <span id="addressError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="address_line2" className="form-label custom_lable">ADDRESS LINE 2 <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="address_line2" name="address_line2" />
                                                    <span id="address_line2Error" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="city" className="form-label custom_lable">CITY <span>*</span></label>
                                                    <input type="text" className="form-control" id="city" name="city" />
                                                    <span id="cityError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="county" className="form-label custom_lable">COUNTY</label>
                                                    <input type="text" className="form-control" id="county" name="county" />
                                                    <span id="countyError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="postcode" className="form-label custom_lable">POSTCODE</label>
                                                    <input type="text" className="form-control" id="postcode" name="postcode" />
                                                    <span id="postcodeError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="mb-4 text-clr">Marketing & Preferences</h2>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="phoneCheck" name="phoneCheck" />
                                                    <label className="form-check-label" htmlFor="phoneCheck">
                                                        Phone
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="emailCheck" name="emailCheck" />
                                                    <label className="form-check-label" htmlFor="emailCheck">
                                                        Email
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="smsCheck" name="smsCheck" />
                                                    <label className="form-check-label" htmlFor="smsCheck">
                                                        SMS
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="postCheck" name="postCheck" />
                                                    <label className="form-check-label" htmlFor="postCheck">
                                                        Post
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mt-5 text-center">
                                                <button className="login-btn w-50">Update Details</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="manage-card" style={selecteddiv == "manage-card" ? { display: 'block' } : { display: 'none' }}>
                        <div className="back_to_home_page">
                            <p>
                                <span onClick={() => {
                                    setselecteddiv('our_services_div');
                                }}>Home -</span>
                                <span> Manage Your Cards</span>
                            </p>
                        </div>
                        <div className="personal_details_div">
                            <div className="row">
                                <div className="col-lg-10 col-md-12 m-auto">
                                    <form action="" className="card_info">
                                        <h2 className="mb-4 text-clr">Add Card</h2>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="name_on_card" className="form-label custom_lable">NAME ON CARD</label>
                                                    <input type="text" className="form-control" id="name_on_card" name="name_on_card" />
                                                    <span id="name_on_cardError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="card_number" className="form-label custom_lable">CARD NUMBER</label>
                                                    <input type="text" className="form-control" id="card_number" name="card_number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                    <span id="card_numberError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="expiry_mm-yy" className="form-label custom_lable">EXPIRY MM / YY</label>
                                                    <input type="text" className="form-control" id="expiry_mm-yy" name="expiry_mm-yy" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                    <span id="expiry_mm-yyError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="cvc" className="form-label custom_lable">CVC</label>
                                                    <input type="text" className="form-control" id="cvc" name="cvc" maxlength="3" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                    <span id="cvcError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="billing_postcode" className="form-label custom_lable">BILLING POSTCODE</label>
                                                    <input type="text" className="form-control" id="billing_postcode" name="billing_postcode" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                    <span id="billing_postcodeError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mt-4 text-center">
                                                <button className="login-btn w-25">Cancel</button>
                                                <button className="login-btn w-25">Add Card</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="password-change" style={selecteddiv == "password-change" ? { display: 'block' } : { display: 'none' }}>
                        <div className="back_to_home_page">
                            <p>
                                <span onClick={() => {
                                    setselecteddiv('our_services_div');
                                }}>Home -</span>
                                <span> Change Password</span>
                            </p>
                        </div>
                        <div className="personal_details_div">
                            <div className="row">
                                <div className="col-lg-10 col-md-12 m-auto">
                                    <form action="" className="change_password_div" onsubmit="return validateForm()">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="current_password" className="form-label custom_lable">CURRENT PASSWORD <span className="req_clr">*</span></label>
                                                    <input type="password" className="form-control" id="current_password" name="current_password" required />
                                                    <span id="current_passwordError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="new_password" className="form-label custom_lable">NEW PASSWORD <span className="req_clr">*</span></label>
                                                    <input type="password" className="form-control" id="new_password" name="new_password" required />
                                                    <span id="new_passwordError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="confirm_password" className="form-label custom_lable">CONFIRM PASSWORD <span className="req_clr">*</span></label>
                                                    <input type="password" className="form-control" id="confirm_password" name="confirm_password" required />
                                                    <span id="confirm_passwordError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mt-2 text-center">
                                                <button className="login-btn w-25">Change Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
