import { Link } from 'react-router-dom'

export default function Login() {
    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    {/* <div className="robo_img_div t ext-center">
                        <figure>
                            <img src="./images/robo-img.svg" alt="" className="robo_img">
                        </figure>
                        <h1 className="text-white">RoboThink</h1>
                    </div> */}
                    <div className="form_div text-center mt-5">
                        <form action="/" className="login_form">
                            <h2 className="mb-4 text-clr">Login</h2>
                            <div className="mb-4 position-relative">
                                <label htmlFor="inputEmail4" className="form-label custom_lable">Email</label>
                                <input type="email" placeholder="your@email.com" className="form-control" id="inputEmail4" />
                                <span id="emailError" className="error"></span>
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="inputEmail4" className="form-label custom_lable">Password</label>
                                <input type="password" className="form-control" id="inputPassword" />
                                <span id="passwordError" className="error"></span>
                                <span className="pass_hide"><i className="fa-regular fa-eye-slash" id="togglePassword"></i></span>
                            </div>
                            <div className="mb-3">
                                <button className="login-btn">Login</button>
                            </div>
                            <div className="forgotZ_btn_div">
                                <button className="btn_forgot">Forgot Password?</button>
                            </div>
                            <div className="register_div text-center">
                                <Link to="/register" className="btn_forgot">Register</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}
