import React, { useState } from 'react'

export default function MyStudents() {

    const [selecteddiv, setselecteddiv] = useState('our_services_div');
    // const [backpage, setbackpage] = useState('our_servives_div');

    return (
        <div>
            <main className="main-bg">
                <div className="container">
                    <div className="our_services_div" id="our_services_div" style={selecteddiv == "our_services_div" ? { display: 'block' } : { display: 'none' }}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_1" id="club_div_1"
                                    onClick={() => {
                                        setselecteddiv('edit-account');
                                    }}>
                                    <h2 className="club_title">Edit Student</h2>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_2" onclick="toggleDivs('manage-card' ,'our_services_div')">
                                    <h2 className="club_title">Edit Media</h2>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="club_card card_3"
                                    onClick={() => {
                                        setselecteddiv('edit_medical');
                                    }}>
                                    <h2 className="club_title">Edit Medical</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="school_for_club" id="edit-account" style={selecteddiv == "edit-account" ? { display: 'block' } : { display: 'none' }}>
                        <div className="back_to_home_page">
                            <p>
                                <span onClick={() => {
                                    setselecteddiv('our_services_div');
                                }}>Home -</span>
                                <span> Edit Student</span>
                            </p>
                        </div>
                        <div className="personal_details_div">
                            <div className="row">
                                <div className="col-lg-10 col-md-12 m-auto">
                                    <form action="" className="edit_student" onsubmit="return validateForm()">
                                        <h2 className="mb-4 text-clr">Edit Student</h2>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="first_name" className="form-label custom_lable">FIRST NAME <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="first_name" name="first_name" />
                                                    <span id="first_nameError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="last_name" className="form-label custom_lable">LAST NAME <span className="req_clr">*</span></label>
                                                    <input type="text" className="form-control" id="last_name" name="last_name" />
                                                    <span id="last_nameError" className="error"></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-10">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="gender" className="form-label custom_lable">GENDER</label>
                                                    <select name="gender" id="gender" className="select_pro">
                                                        <option value="Please Select (Optional)">Please Select (Optional)</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="relationship" className="form-label custom_lable">CUSTOMER RELATIONSHIP</label>
                                                    <select name="relationship" id="relationship" className="select_pro">
                                                        <option value="Mother">Mother</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Sibling">Sibling</option>
                                                        <option value="Carer">Carer</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Myself">Myself</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4 position-relative">
                                                    <label htmlFor="dob" className="form-label custom_lable">DATE OF BIRTH <span>*</span></label>
                                                    <input type="date" className="form-control" id="dob" name="dob" />
                                                    <span id="dobError" className="error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mt-5 text-center">
                                                <button className="login-btn w-50">Update Student</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="school_for_club" id="edit_medical" style={selecteddiv == "edit_medical" ? { display: 'block' } : { display: 'none' }}>
                        <div className="back_to_home_page">
                            <p>
                                <span onClick={() => {
                                    setselecteddiv('our_services_div')
                                }}>Home -</span>
                                <span> Update Medical Information</span>
                            </p>
                        </div>
                        <div className="personal_details_div">
                            <div className="row">
                                <div className="d-flex gap-5 justify-content-center pad_tab" id="myTab" role="tablist">
                                    <div className="nav-item" role="presentation">
                                        <button className="nav-link active" id="tab1-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-selected="true">Medical</button>
                                    </div>
                                    <div className="nav-item " role="presentation">
                                        <button className="nav-link" id="tab2-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-selected="false">Emergency Contact</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 col-md-12 m-auto tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-link" tabindex="0">
                                        <form action="" className="medical_information" id="medical_information">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">ALLERGIES? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option1" name="radioOption" value="option1" />
                                                            <label className="form-check-label" htmlFor="option1">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option2" name="radioOption" value="option2" checked />
                                                            <label className="form-check-label" htmlFor="option2">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">LIST ALLERGIES <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">CONDITIONS? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option3" name="radioOption1" value="option3" />
                                                            <label className="form-check-label" htmlFor="option3">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option4" name="radioOption1" value="option4" checked />
                                                            <label className="form-check-label" htmlFor="option4">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer2" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">LIST CONDITIONS <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">RECENT OPERATIONS? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option5" name="radioOption2" value="option5" />
                                                            <label className="form-check-label" htmlFor="option5">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option6" name="radioOption2" value="option6" checked />
                                                            <label className="form-check-label" htmlFor="option6">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer3" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">LIST CONDITIONS <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">VISION IMPAIRMENTS? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option11" name="radioOption11" value="option11" />
                                                            <label className="form-check-label" htmlFor="option11">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option21" name="radioOption11" value="option21" checked />
                                                            <label className="form-check-label" htmlFor="option21">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer11" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">LIST RECENT OPERATIONS <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">HEARING IMPAIRMENTS? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option31" name="radioOption12" value="option31" />
                                                            <label className="form-check-label" htmlFor="option31">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option41" name="radioOption12" value="option41" checked />
                                                            <label className="form-check-label" htmlFor="option41">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer21" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">HEARING NOTES <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">GLASSES REQUIRED? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option51" name="radioOption21" value="option51" />
                                                            <label className="form-check-label" htmlFor="option5">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option61" name="radioOption21" value="option61" checked />
                                                            <label className="form-check-label" htmlFor="option6">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer31" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">GLASSES NOTES <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="medical_title">
                                                        <label className="form-label radio_lable">RESPIRATORY PROBLEMS? <span>*</span></label>
                                                    </div>
                                                    <div className="medical_info">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option71" name="radioOption13" value="option71" />
                                                            <label className="form-check-label" htmlFor="option71">Yes</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" id="option81" name="radioOption13" value="option81" checked />
                                                            <label className="form-check-label" htmlFor="option81">No</label>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 mb-4 position-relative" id="inputContainer41" style={{ display: 'none' }}>
                                                        <label htmlFor="card_number" className="form-label custom_lable">RESPIRATORY NOTES <span>*</span></label>
                                                        <input type="text" className="form-control" id="card_number" name="card_number" />
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="mt-3 mb-3 position-relative">
                                                        <label htmlFor="card_number" className="form-label custom_lable">OTHER MEDICAL NOTES <span>*</span></label>
                                                        <textarea className="form-control" rows="2" placeholder="Type 'None' if there are no medical notes"></textarea>
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className=" mb-3 position-relative">
                                                        <label htmlFor="card_number" className="form-label custom_lable">ADDITIONAL SUPPORT NEEDS <span>*</span></label>
                                                        <textarea className="form-control" rows="2" placeholder="Type 'None' if there are no additional support needs"></textarea>
                                                        <span id="card_numberError" className="error"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mt-2 text-center">
                                                    <button className="login-btn w-25" id="switchToTab2">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-link" tabindex="0">
                                        <form action="" className="medical_information">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="mb-4 position-relative">
                                                        <label htmlFor="name_on_card" className="form-label custom_lable">NAME <span>*</span></label>
                                                        <input type="text" className="form-control" id="name_on_card" name="name_on_card" />
                                                        <span id="name_on_cardError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="mb-4 position-relative">
                                                        <label htmlFor="title" className="form-label custom_lable">Title</label>
                                                        <select name="title" id="title" className="select_pro">
                                                            <option value="Mother">Mother</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Spouse">Spouse</option>
                                                            <option value="Sibling">Sibling</option>
                                                            <option value="Carer">Carer</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Myself">Myself</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="mb-4 position-relative">
                                                        <label htmlFor="name_on_card" className="form-label custom_lable">EMAIL <span>*</span></label>
                                                        <input type="email" className="form-control" id="name_on_card" name="name_on_card" />
                                                        <span id="name_on_cardError" className="error"></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="mb-4 position-relative">
                                                        <label htmlFor="name_on_card" className="form-label custom_lable">PHONE <span>*</span></label>
                                                        <input type="email" className="form-control" id="name_on_card" onkeypress='return event.charCode >= 48 && event.charCode <= 57' name="name_on_card" />
                                                        <span id="name_on_cardError" className="error"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mt-5 text-center">
                                                    <button className="login-btn w-25">Confirm Update</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
