import React from 'react'
import mathClubImage from '../images/math_club.svg';
import codingClubImage from '../images/science_club.png';
import scienceClubImage from '../images/coding_club.svg';
import sportsClubImage from '../images/sports_club.svg';
import { Link } from 'react-router-dom';


export default function ClubSelection() {
    return (
        <div>
            <main className="main-bg club_sec_div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/products" className="club_name_card">
                                <div className="club_selection_card" style={{ backgroundImage: `url(${mathClubImage})` }}>
                                    <h2>Math Club</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/products" className="club_name_card">
                                <div className="club_selection_card" style={{ backgroundImage: `url(${codingClubImage})` }}>
                                    <h2>Coding Club</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/products" className="club_name_card">
                                <div className="club_selection_card" style={{ backgroundImage: `url(${scienceClubImage})` }}>
                                    <h2>Science Club</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Link to="/products" className="club_name_card">
                                <div className="club_selection_card" style={{ backgroundImage: `url(${sportsClubImage})` }}>
                                    <h2>Sports club</h2>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
